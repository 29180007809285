import React, { useState } from 'react';
import { Row, Col, Modal, Popconfirm } from 'antd';
import {HomeTwoTone, MailTwoTone} from '@ant-design/icons';
import axios from "axios"
import {BASIC} from '../../api/basic'
import imgUrl_RED_BOOK from '../../assent/QR_code/redbook.jpg'
import imgUrl_TIKTOK from '../../assent/QR_code/tiktok1.jpg'
import imgUrl_WECHAT from '../../assent/QR_code/wechat.jpg'
import imgUrl_LOGO_WECHAT from '../../assent/QR_code/logo/logo_wechat.png'
import imgUrl_LOGO_TIKTOK from '../../assent/QR_code/logo/logo_tiktokl.png'
import imgUrl_LOGO_REDBOOK from '../../assent/QR_code/logo/logo_redbook.png'
import home from "../../styles/home";

const h3 = {
    color: '#9c9c9c',
    fontWeight: 'bold'
}

const isModalOpen = false

export default class footer extends React.Component {
    constructor(props) {
        super (props);
    };

    state = {
        isModalOpen: false,
    };

    showModal = (type) => {
        this.isModalOpen = true;
    };

    handleOk = () => {
        this.setState({ isModalOpen: false });
    };

    handleCancel = () => {
        this.setState({ isModalOpen: false });
    };

    // 记录浏览
    componentWillMount(){
        let _this = this
        axios.get(BASIC.QUERY_COUNTS.url).then(res => {
            _this.count = res.data
            _this.forceUpdate()
            console.warn(_this.count)
        })
    }


    render () {
        return (
            <div style={{height: '200px', color: 'white', position: 'fixed', bottom: '0', left: '0', right: '0'}}>
                <Row style={{background: '#222222', height: '150px', color: '#9c9c9c', padding: '20px', textAlign: 'left'}}>
                    <Col span={5}></Col>
                    <Col span={5}>
                        <h3 style={h3}>解决方案</h3>
                        蓝鲸智云管控系统<br/>
                        可源NOP网络智能化管理平台
                    </Col>
                    <Col span={5}>
                        <h3 style={h3}>联系方式</h3>
                        <HomeTwoTone style={{fontSize: '20px'}} twoToneColor='#ff6666'></HomeTwoTone>&nbsp;&nbsp;&nbsp;上海市浦东新区森兰.美奂大厦北楼C座1206室<br/>
                        <MailTwoTone style={{fontSize: '20px'}} twoToneColor='#ff6666'></MailTwoTone>&nbsp;&nbsp;&nbsp;joseph.li@ensource.cn
                    </Col>
                    <Col span={5}>
                        <h3 style={h3}>关于可源科技</h3>
                        关注可源科技，获取最新网络管理案例、产品使用信息。
                    </Col>
                    <Col span={4}>
                        <Row style={{marginTop: '30px'}}>
                            <Popconfirm
                                style={{padding: '0px'}}
                                title={
                                    <div style={{ textAlign: 'center', marginLeft: '0px'}}>
                                        <img src={imgUrl_WECHAT} alt="示例图片" style={{ width: '200px' }} />
                                    </div>
                                }
                                icon={null} // 去掉图标
                                trigger={'hover'}
                                okButtonProps={{ style: { display: 'none' } }} // 隐藏确认按钮
                                cancelButtonProps={{ style: { display: 'none' } }} // 隐藏取消按钮
                            >
                                <img style={home.bottomQRCodeImage} src={imgUrl_LOGO_WECHAT}/>
                            </Popconfirm>

                            <Popconfirm
                                title={
                                    <div style={{ textAlign: 'center'}}>
                                        <img src={imgUrl_TIKTOK} alt="示例图片" style={{ width: '200px' }} />
                                    </div>
                                }
                                icon={null} // 去掉图标
                                trigger={'hover'}
                                okButtonProps={{ style: { display: 'none' } }} // 隐藏确认按钮
                                cancelButtonProps={{ style: { display: 'none' } }} // 隐藏取消按钮
                            >
                                <img style={home.bottomQRCodeImage} onMouseEnter={this.showModal('tiktok')} src={imgUrl_LOGO_TIKTOK}/>
                            </Popconfirm>

                            <Popconfirm
                                title={
                                    <div style={{ textAlign: 'center'}}>
                                        <img src={imgUrl_RED_BOOK} alt="示例图片" style={{ width: '200px' }} />
                                    </div>
                                }
                                icon={null} // 去掉图标
                                trigger={'hover'}
                                okButtonProps={{ style: { display: 'none' } }} // 隐藏确认按钮
                                cancelButtonProps={{ style: { display: 'none' } }} // 隐藏取消按钮
                            >
                                <img style={home.bottomQRCodeImage} onClick={this.showModal('redbook')} src={imgUrl_LOGO_REDBOOK}/>
                            </Popconfirm>
                        </Row>
                    </Col>
                </Row>
                <Row style={{height: '50px', background: '#000', lineHeight: '50px'}}>
                    <Col span={8}>&nbsp;</Col>
                    <Col span={8}>
                        © Copyrights 可源科技 2017 - 2025. 版权所有.&nbsp;&nbsp;&nbsp;
                        <a href="https://beian.miit.gov.cn">沪ICP备17023761号-1</a>
                    </Col>
                    {/*<Col span={8}>&nbsp;访问数:&nbsp;{this.count}</Col>*/}
                </Row>

                {/*<Modal title="Basic Modal" open={true} onOk={this.handleOk} onCancel={this.handleCancel}>*/}
                {/*    <p>Some contents...</p>*/}
                {/*    <p>Some contents...</p>*/}
                {/*    <p>Some contents...</p>*/}
                {/*</Modal>*/}
            </div>
        )
    };
}
