import React from 'react';
import { Row, Col } from 'antd';
import IP_img from '../../assent/certificate/compress/IPManagement.jpg'
import NOP_img from '../../assent/certificate/compress/NOP.jpg'
import MIDDLEWARE_img from '../../assent/certificate/compress/MIDDLEWARE.jpg'
import REGISTRATION_img from '../../assent/certificate/compress/REGISTRATION.jpg'
import PERF_img from '../../assent/certificate/compress/PERF.jpg'
import TOPO_img from '../../assent/certificate/compress/TOPO.jpg'
import DATABASE_img from '../../assent/certificate/compress/DATABASE.jpg'
import DATABASE01_img from '../../assent/certificate/compress/DATABASE01.jpg'
import LOG_img from '../../assent/certificate/compress/LOG.jpg'
import EVENT_img from '../../assent/certificate/compress/EVENT.jpg'
import FLOW_img from '../../assent/certificate/compress/FLOW.jpg'
import TERMINAL_img from '../../assent/certificate/compress/TERMINAL.jpg'
import AUTO_img from '../../assent/certificate/compress/AUTO.jpg'
import BLUEKING_img from '../../assent/certificate/compress/BLUEKING.jpg'
import PROCESS_img from '../../assent/certificate/compress/PROCESS.jpg'
import ASSETS_img from '../../assent/certificate/compress/ASSETS.jpg'
import PRODUCT_img from '../../assent/certificate/compress/PRODUCT.jpg'
import PRODUCT01_img from '../../assent/certificate/compress/PRODUCT01.jpg'
import CONFIG_img from '../../assent/certificate/compress/CONFIG.jpg'

const titleStyle = {
    textAlign: 'center',
    margin: '30px auto',
    fontWeight: '900',
    fontSize: '30px'
}

const imgStyle = {
    width: '100%',
    height: '600px',
    padding: 'auto 30px'
}

const blockStyle = {

}

export default class Certificate extends React.Component {
    constructor() {
        super();
    };

    render() {
        return (
            <div style={blockStyle}>
                <Row>
                    <Col span={2}>&nbsp;</Col>
                    <Col span={20}>
                        <Row>
                            <h1 style={titleStyle}>软件产品证书</h1>
                        </Row>
                        <Row style={{align: 'center'}}>
                            <Col span={4}></Col>
                            <Col span={8}><img style={{width: '100%', height: '400px', margin: '30px 0'}} src={PRODUCT_img}/></Col>
                            <Col span={8}><img style={{width: '100%', height: '400px', margin: '30px 0'}} src={PRODUCT01_img}/></Col>
                            <Col span={4}></Col>
                        </Row>
                        <Row>
                            <h1 style={titleStyle}>作品登记证书</h1>
                        </Row>
                        <Row>
                            <Col span={7}></Col>
                            <Col span={10}><img style={{width: '100%', height: '500px', margin: '30px auto'}} src={REGISTRATION_img}/></Col>
                            <Col span={7}></Col>
                        </Row>
                        <Row>
                            <h1 style={titleStyle}>蓝鲸技术合作伙伴</h1>
                        </Row>
                        <Row>
                            <Col span={7}></Col>
                            <Col span={10}><img style={{width: '100%', height: '400px', margin: '30px auto'}} src={BLUEKING_img}/></Col>
                            <Col span={7}></Col>
                        </Row>
                        <Row>
                            <h1 style={titleStyle}>软件著作权登记证书</h1>
                        </Row>
                        <Row gutter={24}>
                            <Col span={6}><img style={imgStyle} src={IP_img}/></Col>
                            <Col span={6}><img style={imgStyle} src={NOP_img}/></Col>
                            <Col span={6}><img style={imgStyle} src={PERF_img}/></Col>
                            <Col span={6}><img style={imgStyle} src={MIDDLEWARE_img}/></Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={6}><img style={imgStyle} src={TOPO_img}/></Col>
                            <Col span={6}><img style={imgStyle} src={DATABASE_img}/></Col>
                            <Col span={6}><img style={imgStyle} src={DATABASE01_img}/></Col>
                            <Col span={6}><img style={imgStyle} src={LOG_img}/></Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={6}><img style={imgStyle} src={EVENT_img}/></Col>
                            <Col span={6}><img style={imgStyle} src={FLOW_img}/></Col>
                            <Col span={6}><img style={imgStyle} src={TERMINAL_img}/></Col>
                            <Col span={6}><img style={imgStyle} src={AUTO_img}/></Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={2}></Col>
                            <Col span={6}><img style={imgStyle} src={PROCESS_img}/></Col>
                            <Col span={1}></Col>
                            <Col span={6}><img style={imgStyle} src={ASSETS_img}/></Col>
                            <Col span={1}></Col>
                            <Col span={6}><img style={imgStyle} src={CONFIG_img}/></Col>
                            <Col span={2}></Col>
                        </Row>
                    </Col>
                    <Col span={2}>&nbsp;</Col>
                </Row>

            </div>
        )
    }
}
