import React from 'react';
import { Row, Col } from 'antd';
import Record from "../common/record"
import img01 from '../../assent/NOP2021/CMDB.jpg'
import img02 from '../../assent/NOP2021/per.png'
import img03 from '../../assent/NOP2021/EVENT.jpg'
import img04 from '../../assent/NOP2021/TOPO.png'
import img05 from '../../assent/NOP2021/job.png'
import img06 from '../../assent/NOP2021/DASHBOARD.jpg'

const synopsis = {
    background: '#FFFFFF',
    height: '300px',
    marginTop: '20px',

};
const synopsisB = {
    background: '#F4F4F4',
    height: '100%',
    marginTop: '20px',
};
const carouselStyle = {
    width: '100%',
    float: 'left'
};
const font = {
    textAlign: 'left',
    textIndent: '2em'
}
const fonth1 = {
    fontSize: '40px',
    fontWeight: '900'
}

const fonth12 = {
    fontSize: '30px',
    fontWeight: '700'
}
const pd = {
    padding: '20px'
}

export default class Solution extends React.Component {
    constructor() {
        super ();
    };
    render () {
        return (
            <div>
                <Record assemblyName='NOP解决方案'></Record>
                <Row>
                    <Col span={3}></Col>
                    <Col span={8} style={synopsis}>
                        <h1 style={{textAlign: 'left',marginLeft: '30px'}}>IT运维管理的挑战</h1>
                        <ul style={{textAlign: 'left'}}>
                            <li>我们到底有多少在线的网络设备，模块，服务器，数据库等IT资产？</li>
                            <li>这些系统的软硬件版本，模块，生命周期情况怎样？</li>
                            <li>IT系统日常变更导致资产数据变化，怎么及时准确维护数据？</li>
                            <li>IT服务如果出了问题，是否能快速定位和恢复？</li>
                            <li>运维部门人少活多，怎么提升运维效率？</li>
                            <li>大量重复的运维工作能否系统自动帮助执行？</li>
                            <li>怎么减轻运维人员的负担，快乐运维？</li>
                            <li>公司IT系统整体运维水平到底处于什么状态？</li>
                        </ul>
                    </Col>
                    <Col span={10}></Col>
                    <Col span={3}></Col>
                </Row>
                <div style={synopsisB}>
                    <Row>
                        <Col span={3}></Col>
                        <Col span={18}>
                            <h1 style={fonth1}>NOP的解决方案</h1>
                            <div>NOP是基于大数据和开源架构；基于HTML5展现框架的分布式网络，系统和应用的综合监控管理平台(NMS+APM+CM)，是一个可以进行高度客户化的开放系统。</div>
                        </Col>
                        <Col span={3}></Col>
                    </Row>
                    <Row gutter={84}>
                        <Col span={3}></Col>
                        <Col span={6} style={pd}>
                            <h1 style={fonth12}>在线设备管理</h1>
                            <img style={carouselStyle} src={img01}/>
                            <ul style={{textAlign: 'left'}}>
                                <li>全网自动扫描发现设备及其模块，自动判断设备类型，自动归档。</li>
                                <li>自动发现设备软件件变化（型号、软件版本等）、设备改名或变更管理IP地址，自动更新数据并通知，无需用户参与。</li>
                                <li>自动轮询资产及设备状态，并自动分析判断设备上线、更换、下线拆除，并自动更新设备数据库。</li>
                                <li>可节省大量用户日常维护工作量，提升运维效率，数据准确性和实时性，实现随需应变的运维基础数据自动化管理。</li>
                            </ul>
                        </Col>
                        <Col span={6} style={pd}>
                            <h1 style={fonth12}>性能管理</h1>
                            <img style={carouselStyle} src={img02}/>
                            <ul style={{textAlign: 'left'}}>
                                <li>采用分布式的性能数据采集方式，能大规模同一时间采集数几十万至上百万的性能数据点，并且易于扩展。</li>
                                <li>KPI、采集时间间隔完全客户化自定义配置，支持KPI动态表达式计算，可对设定的节点组、端口组进行统一配置。</li>
                                <li>使用HTML5技术对性能数据进行展现，支持柱状图、折线图、饼图等多种图表，并提供RealTime实时图表。</li>
                                <li>灵活的阀值报警策略，支持二级阀值并可自定义报警消息正文。</li>
                            </ul>
                        </Col>
                        <Col span={6} style={pd}>
                            <h1 style={fonth12}>事件管理</h1>
                            <img style={carouselStyle} src={img03}/>
                            <ul style={{textAlign: 'left'}}>
                                <li>支持丰富，压缩，对冲，关联，合并，转发，抑制，自动降级，降噪，自动化等处理</li>
                                <li>支持声光电，短信等高级方式，支持自动升级告警处理</li>
                                <li>基于正则表达式的事件预处理模板</li>
                                <li>主要有两类初级分析处理模板：syslog事件模板和分析trap事件的模板</li>
                                <li>可选用快数据技术进行强大高效的事件处理</li>
                                <li>集中分析处理来自不同厂商的网络设备事件，对不同格式的网络设备事件均可实现定制化处理</li>
                                <li>多种高级事件处理模板功能，直接配置即可完成事件的复杂关联分析处理，如关联丰富</li>
                                <li>将事件的业务影响作为事件处理模型的一个维度，事件业务影响分析直接集成到事件处理过程中</li>
                                <li>通过部分高级模板配置实现部分事件深度分析处理</li>
                                <li>可选用 “快数据”进行高效分析处理，能实现秒级的事件智能化深入处理，大大提高了事件的智能化分析能力；强大的事件高级分析处理模板，将复杂的事件处理二次开发变为简单的模板配置</li>
                            </ul>
                        </Col>
                        <Col span={3}></Col>
                    </Row>
                    <Row gutter={84}>
                        <Col span={3}></Col>
                        <Col span={6} style={pd}>
                            <h1 style={fonth12}>拓扑管理</h1>
                            <img style={carouselStyle} src={img04}/>
                            <ul style={{textAlign: 'left'}}>
                                <li>支持全网自动发现异构网络环境的物理和逻辑拓扑，包括冗余链路的发现；同时自动发现和手动相结合</li>
                                <li>拓扑具有层次结构</li>
                                <li>活动和全景拓扑：关联实时事件，实时告警，导航设备信息、事件列表信息等</li>
                            </ul>
                        </Col>
                        <Col span={6} style={pd}>
                            <h1 style={fonth12}>自动化运维</h1>
                            <img style={carouselStyle} src={img05}/>
                            <ul style={{textAlign: 'left'}}>
                                <li>设备配置的备份</li>
                                <li>自动化配置下发</li>
                                <li>配置变更比对</li>
                                <li>标准化配置审核</li>
                                <li>自动化健康检查</li>
                            </ul>
                        </Col>
                        <Col span={6} style={pd}>
                            <h1 style={fonth12}>灵活的可自定义门户界面</h1>
                            <img style={carouselStyle} src={img06}/>
                        </Col>
                        <Col span={3}></Col>
                    </Row>
                </div>
            </div>
        )
    }
}
