import React from 'react';
import { Row, Col } from 'antd';
import Record from "../common/record"
import imgUrl01 from "../../assent/NOP2021/login.jpg";

const synopsis = {
    background: '#FFFFFF',
    height: '300px',
    marginTop: '30px',

};
const synopsisB = {
    background: '#F4F4F4',
    height: '600px',
    marginTop: '20px',
};
const carouselStyle = {
    height:'300px',
    float: 'left'
};
const font = {
    textAlign: 'center',
}

const font01 = {
    fontSize: '12px',
    textAlign: 'left',
    textIndent: '2em'
}

const fonth1 = {
    fontSize: '40px',
    fontWeight: '900'
}

const fonth12 = {
    fontSize: '30px',
    fontWeight: '700'
}
const pd = {
    padding: '20px'
}

export default class NopSystem extends React.Component {
    constructor() {
        super ();
    };
    render () {
        return (
            <div>
                <Record assemblyName='NOP网络智能化管理平台'></Record>
                <Row gutter={10}>
                    <Col span={3}></Col>
                    <Col span={9} style={synopsis}>
                        <img style={carouselStyle} src={imgUrl01}/>
                    </Col>
                    <Col span={9} style={synopsis}>
                        <h1 style={font}>产品简介</h1>
                        <div style={font01}>NOP网络智能化管理平台是一款能够全面监控全网段服务质量、快速发现并定位影响关键业务性能和故障问题的网络监控和自动化运维产品。该系统以最大提升运维人员的运维效率，以及故障分析处理能力为研发目标，为客户提供实时性能监控和快速故障定位的分析功能。集中收集全网段的实时资产、性能、事件告警数据，以图形化、图表化的方式为运维人员直观呈现IT服务状况。</div>
                    </Col>
                    <Col span={3}></Col>
                </Row>
                <div style={synopsisB}>
                    <Row>
                        <Col span={3}></Col>
                        <Col span={18}>
                            <h1 style={fonth1}>功能特色</h1>
                            <div>可源科技NOP网络智能化管理平台</div>
                        </Col>
                        <Col span={3}></Col>
                    </Row>
                    <Row gutter={84}>
                        <Col span={3}></Col>
                        <Col span={6} style={pd}>
                            <h1 style={fonth12}>设备管理</h1>
                            <div style={font}>智能化的设备管理：自动发现被管设备，自动实时发现设备变化；自动实时轮询设备状态；实时关联库存信息</div>
                        </Col>
                        <Col span={6} style={pd}>
                            <h1 style={fonth12}>性能管理</h1>
                            <div style={font}>基于大数据的分布式高并发的大规模数据采集和分析统计；完全自定义的性能管理：客户完全自定义化的KPI指标体系，灵活的多级性能阈值定义及告警</div>
                        </Col>
                        <Col span={6} style={pd}>
                            <h1 style={fonth12}>事件管理</h1>
                            <div style={font}>采用先进的内存网格计算技术，对海量事件进行高速关联分析处理；强大的事件预处理和高级策略处理机制满足了用户的各种需求</div>
                        </Col>
                        <Col span={3}></Col>
                    </Row>
                    <Row gutter={84}>
                        <Col span={3}></Col>
                        <Col span={6} style={pd}>
                            <h1 style={fonth12}>拓扑管理</h1>
                            <div style={font}>自动生成网络拓扑，自动发现和手动相结合，拓扑具有层次结构，网络事件及性能指标可关联到拓扑</div>
                        </Col>
                        <Col span={6} style={pd}>
                            <h1 style={fonth12}>作业调度</h1>
                            <div style={font}>自动备份及命令下发，自动比对配置变更及标注化配置审核，自动化网络设备健康检查</div>
                        </Col>
                        <Col span={6} style={pd}>
                            <h1 style={fonth12}>IP地址管理</h1>
                            <div style={font}>网络以及IT实施复杂性的增加使网络团队不得不使用自动化IP地址管理工具，我们提供了自动化IP地址管理工具可以让管理员分配子网、分配追踪回收IP地址以及提供对网络的可视性</div>
                        </Col>
                        <Col span={3}></Col>
                    </Row>
                    <Row gutter={84}>
                        <Col span={3}></Col>
                        <Col span={6} style={pd}>
                            <h1 style={fonth12}>流量分析</h1>
                            <div style={font}>分析流入和流出流量，获取产生流量的应用程序排行，可从每个列出的应用程序中查看主机和会话排行</div>
                        </Col>
                        <Col span={6} style={pd}>
                            <h1 style={fonth12}>权限中心</h1>
                            <div style={font}>可从租户，用户组，角色，用户四个维度区分用户的数据权限，从而做到分域分权</div>
                        </Col>
                        <Col span={6} style={pd}>
                            <h1 style={fonth12}>模块工具</h1>
                            <div style={font}>功能强大的报表引擎，报表的展示维度、报表数据的计算和统计都可灵活定制与修改</div>
                        </Col>
                        <Col span={3}></Col>
                    </Row>
                </div>
            </div>
        )
    }
}
