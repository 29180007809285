export default {
    tabImages: { width: '100%', height: '300px' },

    tabText: { padding: '40px', textAlign: 'left', fontSize: '18px' },

    table: { fontWeight: 'bold', marginTop: '40px' },

    table01: { border: '1px solid #333', textAlign: 'left', padding: '40px' },

    table02: { borderTop: '1px solid #333', borderRight: '1px solid #333', textAlign: 'left', padding: '40px' },

    table03: { borderLeft: '1px solid #333', borderBottom: '1px solid #333', textAlign: 'left', padding: '40px' },

    table04: { border: '1px solid #333', textAlign: 'left', padding: '40px' },

    bottomCol: { marginTop: '40px', height: '200px', color: '#fff', textAlign: 'left' },

    bottom1Col: { background: '#2295CD', padding: '50px' },

    bottom2Col: { background: '#ADD540', padding: '50px' },

    bottomQRCodeImage: { height: '50px', width: '50px', marginLeft: '10px'}
}
